:global(#app) {
  .editButton {
    background: transparent;
    box-shadow: none;
    color: #fff;
    font-size: 15px;
    line-height: 34px;
    margin-left: 8px;
    opacity: 0;
    padding: 0;
    width: 34px;

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }
  }

  .item {
    cursor: auto;
    user-select: auto;

    &:before {
      background: none;
    }

    &:active,
    &:hover {
      background: transparent;
      color: rgba(255, 255, 255, 0.9);

      .target {
        opacity: 1;
      }
    }
  }

  .itemHoverable:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.32);
  }

  .logo {
    color: #fff;
    flex: 0 0 auto;
    letter-spacing: 3.5px;
    line-height: 50px;
    padding: 0 16px;

    &:before {
      background: none;
    }
  }

  .menu {
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    flex: 1 1 auto;
    height: 50px;
    margin: 0;
    width: 100%;
  }

  .notification {
    background: #eb5a46;
    border-radius: 8px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    height: 16px;
    line-height: 16px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 8px;
    width: 16px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .userName {
    margin-right: 10px;
  }

  .wrapper {
    background: rgba(0, 0, 0, 0.24);
    display: flex;
    flex: 0 0 auto;
  }
}
